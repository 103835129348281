/* eslint-disable */
import CheckInService from '../../api/CheckInService.js'
import MD5 from 'js-md5'

export default{
	name:'ForgetPasswordSec',
	data(){
		return{
			userInfo:'',
			userPassword:'',
			confirmPassword:''
		}
	},
	methods:{
		GetUserInfo(){
			var that = this
			CheckInService.GetUserPerInfo('',that.$route.query.phone).then(res=>{
				if(res.Tag == 1){
					that.userInfo = res.Result
				}
			})
		},
		CommitPassword(){ //重置密码
			var that = this
			if(that.userPassword.trim() == ''){
				$('#userPassword').addClass('error')
				$('#userPassword .errorts').text('请输入正确密码')
				return;
			}else if(that.userPassword.trim().length < 6 || that.userPassword.trim().length > 20){
				$('#userPassword').addClass('error')
				$('#userPassword .errorts').text('请输入6-20字符的确认密码')
				return
			}else if(that.confirmPassword.trim() == ''){
				$('#userPassword').removeClass('error')
				$('#userPassword .errorts').text('')
				$('#confirmPassword').addClass('error')
				$('#confirmPassword .errorts').text('请输入正确密码')
				return;
			}else if(that.confirmPassword.trim().length < 6 || that.confirmPassword.trim().length > 20){
				$('#userPassword').removeClass('error')
				$('#userPassword .errorts').text('')
				$('#confirmPassword').addClass('error')
				$('#confirmPassword .errorts').text('请输入6-20字符的确认密码')
				return
			}else if(that.confirmPassword.trim() != that.userPassword.trim()){
				$('#userPassword').removeClass('error')
				$('#userPassword .errorts').text('')
				$('#confirmPassword').addClass('error')
				$('#confirmPassword .errorts').text('两次输入的密码不一致')
				return
			}
			$('#userPassword').removeClass('error')
			$('#userPassword .errorts').text('')
			$('#confirmPassword').removeClass('error')
			$('#confirmPassword .errorts').text('')
			CheckInService.EditUserPassword(that.userInfo.ID,that.userInfo.UserPwd,MD5(that.userPassword.trim())).then(res=>{
				if(res.Tag == 1){
					that.$router.go(-2)
				}
			})
		}
	},
	created(){
		this.GetUserInfo()
	}
}